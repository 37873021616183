export const AUTHOR_LOGIN_SUCCESS = 'AUTHOR_LOGIN_SUCCESS'
export const AUTHOR_LOGIN_ERROR = 'AUTHOR_LOGIN_ERROR'

export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'
export const ERROR_CLEAR = 'ERROR_CLEAR'

export const AUTHOR_LOGOUT_SUCESS = 'AUTHOR_LOGOUT_SUCESS'
export const AUTHOR_LOGOUT_FAIL = 'AUTHOR_LOGOUT_FAIL'

export const AUTHOR_LOGOUT_FAIL_CLEAR = 'AUTHOR_LOGOUT_FAIL_CLEAR'
export const AUTHOR_LOGOUT_SUCESS_CLEAR = 'AUTHOR_LOGOUT_SUCESS_CLEAR'

export const AUTHOR_UPDATE_FAIL = 'AUTHOR_UPDATE_FAIL'

export const AUTHOR_UPDATE_SUCCESS = 'AUTHOR_UPDATE_SUCCESS'